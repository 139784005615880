import { apis } from "../../index";
import { AxiosPromise, AxiosRequestConfig } from "axios";

const BASE_URL = "https://xxl6-bbr3-kxzs.m2.xano.io/api";

const branch_registration = `${BASE_URL}:yyd3i3Ww/branch`;
const branch_products = `${BASE_URL}:zm5pHrC3/product`;


// Create Branch Registration
export type CreateBranchRegistrationRequestType = Partial<{
    name: string;
    email: string;
    phone: string;
    branch_type: string;
    products: number[];
    address_line_1: string;
    address_line_2: string;
    city: string;
    state: string;
    pincode: number;
    address_proof: string;
    gst: string;
    property_type: string;
    parent_id?: string;
}>;

export type CreateBranchRegistrationResponseType = Partial<{}>;

export const createBranchRegistration = (
    payload: AxiosRequestConfig<CreateBranchRegistrationRequestType>
): AxiosPromise<CreateBranchRegistrationResponseType> => {
    return apis.post(branch_registration, payload);
}
    
export type GetProductsRequestType = Partial<{
    
}>;

export type GetProductsResponseType = Partial<{
    products:{
        id: string | number;
        name: string;
    }[];
}>;

export const getProducts = (
    payload: AxiosRequestConfig<GetProductsRequestType>
): AxiosPromise<GetProductsResponseType> => {
    return apis.get(branch_products, payload);
}
