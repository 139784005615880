import React, { useEffect, useState } from "react";

import { FileUpload, Img, Input, Text } from "../../components";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import Pandummy from "assets/images/Pandummy.png";
import {
  setPanfront,
  setPanfrontBinary,
  setPanNumber,
} from "redux/features/CustomerRegistration/index";

const Step2: React.FC = () => {
  const [file, setFile] = useState<File>();
  const dispatch = useAppDispatch();
  const PanNumber = useAppSelector(
    (state) => state.CustomerRegistration.PanNumber,
  );
  const Panfront = useAppSelector(
    (state) => state.CustomerRegistration.Panfront,
  );

  useEffect(() => {
    if (Panfront instanceof File) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target?.result) {
          dispatch(setPanfrontBinary(e.target.result.toString()));
        }
      };
      reader.readAsDataURL(Panfront);
    }
  }, [Panfront]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setFile(file); // Update the file name state
      const reader = new FileReader();

      reader.onload = () => {
        dispatch(setPanfrontBinary(reader.result as string));
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="flex gap-5">
      <div className="border-solid pl-10 flex md:flex-1 flex-col h-full  md:h-auto items-start justify-start  md:px-5 w-[53%] md:w-full">
        <div className="flex flex-col gap-8 items-start justify-start w-2/3 sm:w-full">
          <div className="flex relative flex-col gap-2.5 items-start justify-center w-full">
            <div
              className={`flex items-center top-0 flex-col after:right-10 ${false ? "after:h-0 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
            >
              <img
                className="relative -left-10 "
                src={`images/overview/ellipse.svg`}
                alt=""
              />
            </div>
            <Text className="text-sm font-medium leading-[17px]">
              Enter PAN card
            </Text>
            <Input
              name="pan_no"
              placeholder="Enter your PAN card number"
              className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
              wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
              onChange={(e: string) => {
                dispatch(setPanNumber(e));
              }}
              value={PanNumber}
            ></Input>
          </div>
          <div className="flex relative flex-col gap-2.5 items-start justify-center w-full">
            <div
              className={`flex items-center top-0 flex-col after:right-10 absolute ${false ? "after:h-0 " : "after:h-0"}  after:relative  after:content-[''] after:border after:border-dashed `}
            >
              <img
                className="relative -left-10 "
                src={`images/overview/ellipse.svg`}
                alt=""
              />
            </div>
            <Text className="text-sm font-medium leading-[17px]">
              Upload PAN card
            </Text>
            <div
              className={`py-1.5 px-3.5 justify-between rounded-md w-full border flex ${file?.name && "bg-[#F2F7FF]"}`}
            >
              <div className="flex gap-3 items-center overflow-hidden">
                <Img src="/images/remit/file.svg" alt="" />
                <p className="truncate">
                  {file?.name ? file?.name : "No file choosen"}
                </p>
              </div>
              <label
                htmlFor="fileupload3"
                className="text-blue-700 border border-blue-700 text-white ml-2 px-4 py-2 rounded-lg whitespace-nowrap"
              >
                {file?.name ? "Re-upload" : "Upload"}
              </label>
              <input
                name="fileupload3"
                type="file"
                id="fileupload3"
                className="hidden"
                onChange={handleFileChange}
                accept="image/*"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="border-solid flex sm:flex-1 flex-col md:h-auto md:hidden items-start justify-start md:px-5 sm:w-full">
        <div className="w-full flex flex-col gap-2">
          <h3 className="bg-[#F5F9FF] text-blue-700 py-3 px-4 rounded-md">
            Verify the details
          </h3>
          <div className="flex py-2 px-4 items-center gap-5">
            <Img src="/images/overview/exclamation.svg" />
            <p className=" text-sm text-black-label">
              PAN name is authorized as per document
            </p>
          </div>
          <div className="flex py-2 px-4 items-center gap-5">
            <Img src="/images/overview/exclamation.svg" />
            <p className=" text-sm text-black-label">
              Only the front of the Pan Card should be visible
            </p>
          </div>
          <div className="flex py-2 px-4 items-center gap-5">
            <Img src="/images/overview/exclamation.svg" />
            <p className=" text-sm text-black-label">
              The image uploaded is completely in the frame
            </p>
          </div>
          <div className="flex py-2 px-4 items-center gap-5">
            <Img src="/images/overview/exclamation.svg" />
            <p className=" text-sm text-black-label">
              Make sure that your details are clearly visible
            </p>
          </div>
        </div>
      </div>
      {/* </div>
      </div> */}
    </div>
  );
};

export default Step2;
