import { configureStore } from "@reduxjs/toolkit";
import PartnerRegistrationSlice from "./features/PartnerRegistration";
import CustomerRegistrationSlice from "./features/CustomerRegistration";
import CreateOrderSlice from "./features/CreateOrder";
import CreateDealSlice from "./features/CreateDeal";
import AddDeclaratinSlice from "./features/AddDeclaration";
import CapturePaymentSlice from "./features/CapturePayment";
import BranchRegistrationSlice from "./features/BranchRegistration";
import CreateStaffSlice from "./features/CreateStaff";
import UserSlice from "./features/User";
import CreateGICOrderSlice from "./features/CreateGICOrder";
import CreateForexQuotationSlice from "./features/CreateForexQuotation";
import CreateOpportunitySlice from "./features/CreateOpportunity";
import CreateRedirectPortalSlice from "./features/Redirect Portal";

export const store = configureStore({
  reducer: {
    PartnerRegistration: PartnerRegistrationSlice,
    CustomerRegistration: CustomerRegistrationSlice,
    CreateOrder: CreateOrderSlice,
    CreateDeal: CreateDealSlice,
    AddDeclaration: AddDeclaratinSlice,
    CapturePayment: CapturePaymentSlice,
    BranchRegistration: BranchRegistrationSlice,
    CreateStaff: CreateStaffSlice,
    CreateOpportunity: CreateOpportunitySlice,
    User: UserSlice,
    CreateGICOrder: CreateGICOrderSlice,
    CreateForexQuotation: CreateForexQuotationSlice,
    CreateRedirectPortal: CreateRedirectPortalSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
