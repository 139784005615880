import { AxiosRequestConfig } from "axios";
import { useDebounce } from "pages/CreateDeal/newdeal";
import { Option } from "pages/Opportunities/types";
import { useState } from "react";
import { useParams } from "react-router-dom";
import ReactSelect from "react-select/creatable";
import { SingleValue } from "react-select";
import {
  setContactPersonName,
  setContactPersonPhone,
  setContactPersonType,
} from "redux/features/CreateOpportunity";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { GetContactReqType, getContacts } from "service/apis/Opportunities";

interface ContactDetailsProps {
  errors: Record<string, string>;
}

const typeOfContactPerson: Option[] = [
  { value: "employee", label: "Employee" },
  { value: "coordinator", label: "Coordinator" },
];

export default function ExistingContactDetail({ errors }: ContactDetailsProps) {
  const { id } = useParams();
  const {
    contact_person_type,
    company_email,
    contact_person_name,
    contact_person_phone,
  } = useAppSelector((state) => state.CreateOpportunity);
  const dispatch = useAppDispatch();
  const [options, setOptions] = useState<Option[]>(typeOfContactPerson);
  const [contacType, setContactType] = useState<Option[]>([]);
  const [selectedContactOption, setSelectedContactOption] =
    useState<Option | null>(null);
  const [inputValue, setInputValue] = useState<string>("");
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const dottedBorderWidth = "after:h-28";

  const handleChange = (newValue: SingleValue<Option>) => {
    setSelectedOption(newValue);
    dispatch(setContactPersonType(newValue?.value ?? ""));
  };

  const fetchContacts = async () => {
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<GetContactReqType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      params: {
        companyId: id,
        number: inputValue,
      },
    };
    try {
      const res = (await getContacts(payload)) as any;
      console.log(
        res?.data?.map((item: any) => {
          return {
            value: item?.contactName,
            label: item?.contactNumber,
          };
        }),
      );
      setContactType(() =>
        res?.data?.[0]?.map((item: any) => {
          return {
            value: item?.contactName,
            label: item?.contactNumber,
          };
        }),
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleContactPersonPhoneChange = (newValue: SingleValue<Option>) => {
    if (newValue) {
      setSelectedContactOption(newValue);
      dispatch(setContactPersonPhone(newValue?.label ?? ""));
      dispatch(setContactPersonName(newValue?.value as string));
    }
  };

  const handleInputChange = (newValue: string) => {
    setInputValue(newValue);
    if (newValue === "") {
      setContactType([]);
    }
  };

  const handleCreate = (inputValue: string) => {
    dispatch(setContactPersonPhone(inputValue));
    setSelectedContactOption({ value: inputValue, label: inputValue });
    setContactType((prev) => [
      ...prev,
      { value: inputValue, label: inputValue },
    ]);
  };

  useDebounce(fetchContacts, 500, [inputValue]);
  return (
    <form className="max-w-md">
      <div className="flex flex-col sm:pl-0 pl-10 gap-8 w-full ">
        <div className="flex relative flex-col gap-3 items-start justify-start w-full">
          <div
            className={`flex sm:hidden  items-center top-0 flex-col after:right-10  ${errors?.company_phone ? "after:h-36" : dottedBorderWidth} after:border-blue-700 absolute  after:relative  after:content-[''] after:border after:border-dashed `}
          >
            <img
              className="relative -left-10 "
              src="/images/overview/ellipse.svg"
              alt=""
            />
          </div>
          <label
            htmlFor="contact_person_phone"
            className="text-sm text-[#6B7280]"
          >
            What is the contact number of the person?{" "}
          </label>
          <ReactSelect
            className="w-full"
            menuPlacement="bottom"
            placeholder="Enter the contact number"
            menuPosition="absolute"
            styles={{
              control: (provided, state) => ({
                ...provided,
                zIndex: 100,
                borderRadius: ".5rem",
                padding: ".6rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderColor: state.isFocused ? "#165FE3" : "#333333",
                boxShadow: "none",
              }),
              menu: (provided) => ({
                ...provided,
                zIndex: 1000,
              }),
            }}
            allowCreateWhileLoading
            onChange={handleContactPersonPhoneChange}
            onCreateOption={handleCreate}
            onInputChange={handleInputChange}
            options={contacType ?? []}
            value={selectedContactOption}
            name="contact_person_phone"
            id="contact_person_phone"
          />
          {errors?.contact_person_phone && (
            <p className="text-red-500 text-sm">
              {errors?.contact_person_phone}
            </p>
          )}
        </div>
        <div className="flex relative flex-col gap-3 items-start justify-start w-full">
          <div
            className={`flex sm:hidden  items-center top-0 flex-col after:right-10  ${errors?.contact_person ? "after:h-36" : dottedBorderWidth} after:border-blue-700 absolute  after:relative  after:content-[''] after:border after:border-dashed `}
          >
            <img
              className="relative -left-10 "
              src="/images/overview/ellipse.svg"
              alt=""
            />
          </div>
          <label htmlFor="contact_person" className="text-sm text-[#6B7280]">
            What is the name of contact person (employee)?
          </label>
          <input
            type="text"
            placeholder="Enter name of the employee"
            onChange={(e) => dispatch(setContactPersonName(e.target.value))}
            id="contact_person"
            value={contact_person_name}
            className="border border-black-label rounded-lg p-4 w-full"
          />
          {errors?.contact_person && (
            <p className="text-red-500 text-sm">{errors.contact_person}</p>
          )}
        </div>
        <div className="flex relative flex-col gap-3 items-start justify-start w-full">
          <div className="flex sm:hidden  items-center top-0 flex-col after:right-10  after:h-0 absolute  after:relative  after:content-[''] after:border after:border-dashed">
            <img
              className="relative -left-10 "
              src="/images/overview/ellipse.svg"
              alt=""
            />
          </div>
          <label
            htmlFor="contact_person_type"
            className="text-sm text-[#6B7280]"
          >
            What is the type of the contact person?
          </label>
          <ReactSelect
            className="w-full"
            menuPlacement="top"
            placeholder="Select the type of the contact person"
            styles={{
              control: (provided, state) => ({
                ...provided,
                zIndex: 100,
                borderRadius: ".5rem",
                padding: ".6rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderColor: state.isFocused ? "#165FE3" : "#333333",
                boxShadow: "none",
              }),
              menu: (provided) => ({
                ...provided,
                zIndex: 1000,
              }),
            }}
            isClearable
            isSearchable
            options={options}
            onChange={handleChange}
            value={
              contact_person_type
                ? {
                    value: contact_person_type,
                    label: options.find(
                      (el) => el.value === contact_person_type,
                    )?.label as string,
                  }
                : selectedOption
            }
            name="contact_person_type"
            id="contact_person_type"
          />
          {errors?.contact_person_type && (
            <p className="text-red-500 text-sm">{errors.contact_person_type}</p>
          )}
        </div>
      </div>
    </form>
  );
}
