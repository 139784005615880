import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { AlertDialogAction } from "@radix-ui/react-alert-dialog";
import { MultiSelect } from "components/MultiSelect/multi-select";
import { useEffect, useState } from "react";
import { getDocumentMapping } from "service/apis/Opportunities";

interface PropsType {
  selectedMode: string[];
  setSelectedMode: (value: string[]) => void;
  expires: string;
  setExpires: (value: string) => void;
  popupnotes: string;
  setPopupNotes: (value: string) => void;
  channel: string;
  setChannel: (value: string) => void;
  onSubmit: () => void;
  buttonState: () => boolean;
}


export default function DocPopup({ selectedMode, buttonState, onSubmit, expires, popupnotes, channel, setChannel, setExpires, setPopupNotes, setSelectedMode }: PropsType) {
    const [docMap, setDocMap] = useState([]);

  const fetchDocMap = async () => {
    try {
      const response = (await getDocumentMapping({})) as any;
      console.log(response.data);
      setDocMap(() =>
        response?.data?.map((doc: any) => ({
          label: doc?.filename,
          value: doc?.id,
        })),
      );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDocMap();
  }, []);

    return (
        <AlertDialog>
            <AlertDialogTrigger className="sm:w-fit">
                <button
                    type="button"
                    disabled={!buttonState()}
                    className="px-4 py-[11px] w-fit rounded-lg bg-blue-700  text-white-A700 disabled:opacity-40"
                >
                    Submit & Collect Documents
                </button>
            </AlertDialogTrigger>
            <AlertDialogPortal>
                <AlertDialogOverlay className=" bg-black-900 bg-opacity-60 animate-overlayShow fixed inset-0" />
                <AlertDialogContent className="bg-white-A700 rounded-lg gap-0 p-0 max-w-2xl max-h-fit overflow-auto animate-contentShow">
                    <AlertDialogHeader className="py-5 px-10 flex border-b">
                        <AlertDialogTitle className="text-xl text-left font-bold">Collect Documents</AlertDialogTitle>
                    </AlertDialogHeader>
                    <div className="flex flex-col gap-6 p-5">
                        <form className="flex flex-col gap-10" onSubmit={() => { }} >
                            <div className="flex flex-col sm:pl-0 pl-10 gap-8 w-full max-w-lg ">
                                <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                                    <div
                                        className={`flex sm:hidden  items-center top-0 flex-col after:right-10  ${"after:h-12"} after:border-blue-700 absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                                    >
                                        <img
                                            className="relative -left-10 "
                                            src="/images/overview/ellipse.svg"
                                            alt=""
                                        />
                                    </div>
                                    <div className="flex flex-row gap-2 h-6 items-center relative justify-start w-full">
                                        Send a link on
                                        <span className="">
                                            <DropdownMenu>
                                                <DropdownMenuTrigger asChild>
                                                    <Button className="h-fit capitalize bg-[#F1F1F1] flex items-center gap-2 py-[5px] px-2.5" variant='default'>
                                                        {channel}
                                                        <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M11.0685 0.157354L6.38346 4.76835C6.28124 4.86948 6.14325 4.9262 5.99946 4.9262C5.85567 4.9262 5.71768 4.86948 5.61546 4.76835L0.93046 0.158354C0.827604 0.0572781 0.689167 0.000641788 0.54496 0.000641775C0.400753 0.000641762 0.262315 0.0572781 0.15946 0.158354C0.108811 0.207731 0.0685592 0.266746 0.0410752 0.331923C0.0135913 0.3971 -0.000567906 0.46712 -0.000567912 0.537854C-0.000567919 0.608589 0.0135913 0.678607 0.0410752 0.743784C0.0685591 0.808961 0.108811 0.867977 0.15946 0.917354L4.84346 5.52735C5.15198 5.83028 5.56708 6 5.99946 6C6.43184 6 6.84694 5.83028 7.15546 5.52735L11.8395 0.917355C11.8903 0.867962 11.9306 0.808885 11.9582 0.743615C11.9858 0.678346 12 0.60821 12 0.537354C12 0.466499 11.9858 0.396364 11.9582 0.331095C11.9306 0.265825 11.8903 0.206747 11.8395 0.157354C11.7366 0.0562787 11.5982 -0.000357663 11.454 -0.000357676C11.3098 -0.000357688 11.1713 0.0562786 11.0685 0.157354Z" fill="#666666" />
                                                        </svg>
                                                    </Button>
                                                </DropdownMenuTrigger>
                                                <DropdownMenuContent className=" p-0 h-fit">
                                                    <DropdownMenuSeparator />
                                                    <DropdownMenuRadioGroup value={channel} onValueChange={setChannel}>
                                                        <DropdownMenuRadioItem className="py-1 cursor-pointer hover:bg-[#F0F5FD]" value="whatsapp">Whatsapp</DropdownMenuRadioItem>
                                                        {/* <DropdownMenuRadioItem className="py-1 cursor-pointer hover:bg-[#F0F5FD]" value="email">Email</DropdownMenuRadioItem>
                                                        <DropdownMenuRadioItem className="py-1 cursor-pointer hover:bg-[#F0F5FD]" value="message">Message</DropdownMenuRadioItem> */}
                                                    </DropdownMenuRadioGroup>
                                                </DropdownMenuContent>
                                            </DropdownMenu>
                                        </span>
                                        which expires in
                                        <span className="">
                                            <DropdownMenu>
                                                <DropdownMenuTrigger asChild>
                                                    <Button className="h-min bg-[#F1F1F1] flex items-center gap-2 py-[5px] px-2.5" variant='default'>
                                                        { expires} hour
                                                        <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M11.0685 0.157354L6.38346 4.76835C6.28124 4.86948 6.14325 4.9262 5.99946 4.9262C5.85567 4.9262 5.71768 4.86948 5.61546 4.76835L0.93046 0.158354C0.827604 0.0572781 0.689167 0.000641788 0.54496 0.000641775C0.400753 0.000641762 0.262315 0.0572781 0.15946 0.158354C0.108811 0.207731 0.0685592 0.266746 0.0410752 0.331923C0.0135913 0.3971 -0.000567906 0.46712 -0.000567912 0.537854C-0.000567919 0.608589 0.0135913 0.678607 0.0410752 0.743784C0.0685591 0.808961 0.108811 0.867977 0.15946 0.917354L4.84346 5.52735C5.15198 5.83028 5.56708 6 5.99946 6C6.43184 6 6.84694 5.83028 7.15546 5.52735L11.8395 0.917355C11.8903 0.867962 11.9306 0.808885 11.9582 0.743615C11.9858 0.678346 12 0.60821 12 0.537354C12 0.466499 11.9858 0.396364 11.9582 0.331095C11.9306 0.265825 11.8903 0.206747 11.8395 0.157354C11.7366 0.0562787 11.5982 -0.000357663 11.454 -0.000357676C11.3098 -0.000357688 11.1713 0.0562786 11.0685 0.157354Z" fill="#666666" />
                                                        </svg>
                                                    </Button>
                                                </DropdownMenuTrigger>
                                                <DropdownMenuContent className=" p-0 h-fit">
                                                    <DropdownMenuSeparator />
                                                    <DropdownMenuRadioGroup className="" value={expires} onValueChange={setExpires}>
                                                        <DropdownMenuRadioItem className="py-1 cursor-pointer hover:bg-[#F0F5FD]" value="1">1 hour</DropdownMenuRadioItem>
                                                        <DropdownMenuRadioItem className="py-1 cursor-pointer hover:bg-[#F0F5FD]" value="24">24 hour</DropdownMenuRadioItem>
                                                    </DropdownMenuRadioGroup>
                                                </DropdownMenuContent>
                                            </DropdownMenu>
                                        </span>
                                    </div>
                                </div>
                                <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                                    <div
                                        className={`flex sm:hidden items-center top-0 flex-col after:right-10  ${"after:h-24"} after:border-blue-700 absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                                    >
                                        <img
                                            className="relative -left-10 "
                                            src="/images/overview/ellipse.svg"
                                            alt=""
                                        />
                                    </div>
                                    <label htmlFor="medium_of_conatc" className="text-sm">Which documents need to be collected ?</label>
                                    <MultiSelect
                                        className="h-11 w-full"
                                        options={docMap}
                                        onValueChange={setSelectedMode}
                                        placeholder="Select documents to be collected"
                                        variant='secondary'
                                        animation={2}
                                        maxCount={1}
                                    />
                                </div>
                                <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                                    <div
                                        className={`flex sm:hidden items-center top-0 flex-col after:right-10  ${true ? "after:h-0" : "after:h-52 after:border-blue-700"}  absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                                    >
                                        <img
                                            className="relative -left-10 "
                                            src="/images/overview/ellipse.svg"
                                            alt=""
                                        />
                                    </div>
                                    <label htmlFor="note" className="text-sm">Note</label>
                                    <textarea placeholder="Write note here...." onChange={e => setPopupNotes(e.target.value)} id="note" value={popupnotes} className="border h-36 border-black-label rounded-lg p-4 w-full" />
                                </div>
                            </div>
                        </form>
                    </div>
                    <AlertDialogFooter className="flex justify-start items-center gap-2.5 py-5 px-10 flex-row rounded-b-lg bg-gray-button-gray-lite">
                            <AlertDialogCancel
                              className=" m-0 rounded-lg border-blue-700 border py-[11px] px-4 text-blue-700"
                              asChild
                            >
                              <button onClick={()=>{
                                    setChannel("whatsapp");
                                    setExpires("1");
                                    setPopupNotes("");
                                    setSelectedMode([]);
                              }}>Cancel</button>
                            </AlertDialogCancel>
                            <AlertDialogAction
                              className="rounded-lg border-blue-700 py-[11px] px-4 bg-blue-700 text-white-A700"
                              asChild
                            >
                              <button
                                className="disabled:opacity-40"
                                disabled={ selectedMode?.length === 0 || expires === "" || popupnotes === "" || channel === ""}
                                onClick={onSubmit}
                              >
                                Submit
                              </button>
                            </AlertDialogAction>
                          </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogPortal>
        </AlertDialog>
    )
}
