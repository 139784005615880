import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ColumnDef } from "@tanstack/react-table";
import { Spinner } from "../../components";
import "../../styles/styles.css";
import { GetStaff } from "../../service/api";
import { StaffType } from "@/lib/validations/schema";
import { DataTableColumnHeader } from "components/Table/data-table-column-header";
import { staff_status_options } from "common/filters";
import { DataTable } from "components/Table/DataTable";
import { stat } from "fs";

const StaffPage = () => {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [RoleOptions, setRoleOptions] = useState<
    { name: string; val: string }[]
  >([]);

  const [perPage, setPerPage] = useState(0);

  const [loading, setLoading] = useState<boolean>(false);
  const [role, setRole] = useState("");
  const navigate = useNavigate();

  const fetchResults = async (
    searchTerm: string,
    page: number,
    perPage: number,
  ) => {
    setLoading(true);
    const payload = {
      q: searchTerm !== "" ? `${searchTerm}:*` : "",
      page,
      per_page: perPage,
      role,
    };

    try {
      const response = await GetStaff(payload);

      if (response) {
        setLoading(false);
        console.log(response?.data?.model?.items);
        setResults(
          response.data.model.items.map((staff: any) => {
            return {
              e_id: staff?.id,
              id: staff.employeeID,
              name: staff.fname,
              role: staff.role,
              staffStatus: staff.is_active ? "active" : "inactive",
              lastLogin: new Date(staff.last_login).toDateString(),
            };
          }),
        );
        setTotalPages(response.data.model.pageTotal);
        if (currentPage > response?.data?.model?.pageTotal) {
          setCurrentPage(response?.data?.model?.pageTotal);
        }
        setRoleOptions(
          response.data.roles
            .map((role: any) => {
              return { name: role, val: role };
            })
            .filter((role: any) => role.val !== ""),
        );
        setRoleOptions((prev) => {
          return [{ name: "All", val: "" }, ...prev] as {
            name: string;
            val: string;
          }[];
        });
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      // console.error("Error fetching results:", error);
    }
  };

  const columns: ColumnDef<StaffType>[] = [
    {
      id: "id",
      accessorKey: "id",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Employee ID" />
      ),
      filterFn: "includesString",
    },
    {
      accessorKey: "name",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Employee Name" />
      ),
    },
    {
      accessorKey: "role",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Role" />
      ),
    },
    {
      accessorKey: "staffStatus",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Staff Status" />
      ),
      cell: ({ row }) => {
        const statusValue = staff_status_options.find(
          (value) => value.value === row.getValue("staffStatus"),
        );

        return (
          <p
            style={{
              backgroundColor: statusValue?.bgColor,
              color: statusValue?.textColor,
            }}
            className="py-[3px] text-sm leading-4 font-medium  px-2 text-center rounded-md"
          >
            {statusValue?.label}
          </p>
        );
      },
    },
    {
      accessorKey: "lastLogin",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Last Login" />
      ),
    },
  ];

  const handleAction = (rowData: any) => {
    navigate(`/staffdetails${rowData.e_id}`);
  };

  useEffect(() => {
    fetchResults(query, currentPage, perPage);
  }, [query, currentPage, perPage, role]);

  return (
    <>
      {loading && <Spinner />}
      <div className="flex bg-white-A700 rounded-xl h-full gap-5 py-5 px-6 w-full flex-col overflow-hidden">
        <div className=" flex w-full justify-between items-center">
          <div className=" flex flex-col gap-2">
            <h3 className=" font-semibold leading-4 text-lg text-black-label">
              Staff
            </h3>
            <p className="font-normal leading-[22.4px] text-sm text-[#7E7E7E]">
              List of all the staff member
            </p>
          </div>
          <Link to={"/createStaff"}>
            <button className="rounded-lg py-[11px] px-4 border bg-blue-700 text-white-A700 font-semibold text-sm">
              Register New Staff
            </button>
          </Link>
        </div>
        <div className="flex-1 relative overflow-auto">
          {loading && <Spinner />}
          <DataTable
            onRowClick={handleAction}
            data={results}
            columns={columns}
            searchData={{
              placeholder: "Search by ID, name",
              columns: ["id", "name"],
            }}
            defaultSorting={[{ id: "id", desc: true }]}
            isPageCountVisible={false}
          />
        </div>
      </div>
    </>
  );
};

export default StaffPage;
