import { OpportunityType } from "@/lib/validations/schema";
import { ColumnDef } from "@tanstack/react-table";
import { AxiosRequestConfig } from "axios";
import { opportunity_status_options } from "common/filters";
import { Spinner } from "components";
import { DataTableColumnHeader } from "components/Table/data-table-column-header";
import { DataTable } from "components/Table/DataTable";
import { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  getOpportunities,
  GetOpportunityReqType,
} from "service/apis/Opportunities";
import { toast, Toaster } from "sonner";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useAppSelector } from "redux/hooks";

const searchOptions = {
  corporate: {
    columns: ["companyName", "id", "product"],
    placeholder: "Search by ID or Company Name",
  },
  retail: {
    columns: ["name", "id", "product"],
    placeholder: "Search by ID or Name",
  },
  partner: {
    columns: ["id", "name", "product"],
    placeholder: "Search by ID or Entity Name",
  },
};

const allcolumns: ColumnDef<OpportunityType>[] = [
  {
    id: "id",
    accessorKey: "id",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="ID" />
    ),
    cell: ({ row }) => <div className="">{row.original.uniqueId}</div>,
    enableHiding: false,
    filterFn: "includesString",
  },
  {
    id: "companyName",
    accessorKey: "companyName",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Company Name" />
    ),
    cell: ({ row }) => {
      return (
        <div className=" flex gap-10 items-center justify-between">
          {row.getValue("companyName")}
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.5 1.59375C7.13408 1.59375 5.79882 1.99879 4.6631 2.75766C3.52737 3.51653 2.64218 4.59514 2.11946 5.85709C1.59674 7.11904 1.45998 8.50766 1.72645 9.84734C1.99293 11.187 2.65069 12.4176 3.61655 13.3835C4.5824 14.3493 5.81298 15.0071 7.15266 15.2735C8.49234 15.54 9.88096 15.4033 11.1429 14.8805C12.4049 14.3578 13.4835 13.4726 14.2423 12.3369C15.0012 11.2012 15.4063 9.86593 15.4063 8.5C15.4043 6.66894 14.6761 4.91343 13.3813 3.61868C12.0866 2.32393 10.3311 1.59568 8.5 1.59375ZM8.23438 4.78125C8.39198 4.78125 8.54605 4.82799 8.6771 4.91555C8.80814 5.00311 8.91028 5.12756 8.97059 5.27317C9.03091 5.41878 9.04669 5.57901 9.01594 5.73359C8.98519 5.88817 8.9093 6.03016 8.79785 6.1416C8.68641 6.25305 8.54442 6.32894 8.38984 6.35969C8.23526 6.39044 8.07504 6.37466 7.92943 6.31434C7.78382 6.25403 7.65936 6.15189 7.5718 6.02084C7.48424 5.8898 7.4375 5.73573 7.4375 5.57812C7.4375 5.36678 7.52146 5.16409 7.6709 5.01465C7.82035 4.86521 8.02303 4.78125 8.23438 4.78125ZM9.03125 12.2188C8.74946 12.2188 8.47921 12.1068 8.27995 11.9076C8.08069 11.7083 7.96875 11.438 7.96875 11.1562V8.5C7.82786 8.5 7.69273 8.44403 7.5931 8.3444C7.49347 8.24477 7.4375 8.10965 7.4375 7.96875C7.4375 7.82785 7.49347 7.69273 7.5931 7.5931C7.69273 7.49347 7.82786 7.4375 7.96875 7.4375C8.25055 7.4375 8.5208 7.54944 8.72005 7.7487C8.91931 7.94796 9.03125 8.21821 9.03125 8.5V11.1562C9.17215 11.1562 9.30728 11.2122 9.4069 11.3118C9.50653 11.4115 9.5625 11.5466 9.5625 11.6875C9.5625 11.8284 9.50653 11.9635 9.4069 12.0632C9.30728 12.1628 9.17215 12.2188 9.03125 12.2188Z"
                    fill="#666666"
                  />
                </svg>
              </TooltipTrigger>
              <TooltipContent className="bg-black-900 flex-col text-white-A700 flex p-3 gap-2.5 items-center border-none rounded-lg">
                <div className=" border-b pb-2 border-[#666666]">
                  {row.getValue("companyName")}
                </div>
                {row.original.assignedTo}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      );
    },
    filterFn: "includesString",
    enableSorting: false,
  },
  {
    id: "name",
    accessorKey: "name",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Name" />
    ),
    enableSorting: false,
  },
  {
    id: "entityName",
    accessorKey: "entityName",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Entity Name" />
    ),
    cell: ({ row }) => {
      return (
        <div className=" flex gap-10 capitalize items-center justify-between">
          {row.original.name}
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.5 1.59375C7.13408 1.59375 5.79882 1.99879 4.6631 2.75766C3.52737 3.51653 2.64218 4.59514 2.11946 5.85709C1.59674 7.11904 1.45998 8.50766 1.72645 9.84734C1.99293 11.187 2.65069 12.4176 3.61655 13.3835C4.5824 14.3493 5.81298 15.0071 7.15266 15.2735C8.49234 15.54 9.88096 15.4033 11.1429 14.8805C12.4049 14.3578 13.4835 13.4726 14.2423 12.3369C15.0012 11.2012 15.4063 9.86593 15.4063 8.5C15.4043 6.66894 14.6761 4.91343 13.3813 3.61868C12.0866 2.32393 10.3311 1.59568 8.5 1.59375ZM8.23438 4.78125C8.39198 4.78125 8.54605 4.82799 8.6771 4.91555C8.80814 5.00311 8.91028 5.12756 8.97059 5.27317C9.03091 5.41878 9.04669 5.57901 9.01594 5.73359C8.98519 5.88817 8.9093 6.03016 8.79785 6.1416C8.68641 6.25305 8.54442 6.32894 8.38984 6.35969C8.23526 6.39044 8.07504 6.37466 7.92943 6.31434C7.78382 6.25403 7.65936 6.15189 7.5718 6.02084C7.48424 5.8898 7.4375 5.73573 7.4375 5.57812C7.4375 5.36678 7.52146 5.16409 7.6709 5.01465C7.82035 4.86521 8.02303 4.78125 8.23438 4.78125ZM9.03125 12.2188C8.74946 12.2188 8.47921 12.1068 8.27995 11.9076C8.08069 11.7083 7.96875 11.438 7.96875 11.1562V8.5C7.82786 8.5 7.69273 8.44403 7.5931 8.3444C7.49347 8.24477 7.4375 8.10965 7.4375 7.96875C7.4375 7.82785 7.49347 7.69273 7.5931 7.5931C7.69273 7.49347 7.82786 7.4375 7.96875 7.4375C8.25055 7.4375 8.5208 7.54944 8.72005 7.7487C8.91931 7.94796 9.03125 8.21821 9.03125 8.5V11.1562C9.17215 11.1562 9.30728 11.2122 9.4069 11.3118C9.50653 11.4115 9.5625 11.5466 9.5625 11.6875C9.5625 11.8284 9.50653 11.9635 9.4069 12.0632C9.30728 12.1628 9.17215 12.2188 9.03125 12.2188Z"
                    fill="#666666"
                  />
                </svg>
              </TooltipTrigger>
              <TooltipContent className="bg-black-900 flex-col text-white-A700 flex p-3 gap-2.5 items-center border-none rounded-lg">
                <div className=" border-b pb-2 border-[#666666]">
                  {row.original.name}
                </div>
                {row.original.assignedTo}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      );
    },
    enableSorting: false,
  },
  {
    id: "product",
    accessorKey: "product",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Product" />
    ),
    filterFn: (row, id, value) => {
      return String(row.getValue(id))
        .toLowerCase()
        .includes(String(value).toLowerCase());
    },
    enableSorting: false,
  },
  {
    id: "assignedTo",
    accessorKey: "assignedTo",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Assigned To" />
    ),
    cell: ({ row }) => {
      const name =
        row.getValue("assignedTo") ?? localStorage.getItem("owner_name");
      return <p className="capitalize">{name}</p>;
    },
    filterFn: (row, id, value) => {
      return String(row.getValue(id))
        .toLowerCase()
        .includes(String(value).toLowerCase());
    },
    enableSorting: false,
  },
  {
    accessorKey: "createDate",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Create Date " />
    ),
    filterFn: (row, id, value) => {
      return String(row.getValue(id))
        .toLowerCase()
        .includes(String(value).toLowerCase());
    },
  },
  {
    accessorKey: "opportunityStatus",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Status" />
    ),
    cell: ({ row }) => {
      const status = opportunity_status_options.find(
        (status) => status.value === row.getValue("opportunityStatus"),
      );

      if (!status) {
        return null;
      }

      return (
        <p
          style={{ backgroundColor: status.bgColor, color: status.textColor }}
          className="py-[3px] text-sm leading-4 font-medium  px-2 text-center rounded-md"
        >
          {status.label}
        </p>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
    enableSorting: false,
  },
];

export default function OpportunitiesOverview() {
  const [opportunities, setOpportunities] = useState<OpportunityType[]>([]);
  const { pipelineType } = useAppSelector((state) => state.CreateOpportunity);
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const statusValue = (status: string) => {
    return status === "true" ? "open" : "close";
  };

  const handleRowClick = (row: OpportunityType) => {
    if (pipelineType === "corporate") {
      navigate(`/opportunities/opportunitydetails/${row.companyId}`);
      return;
    }
    navigate(`/opportunities/opportunitydetails/${row.contactId}`);
  };

  const columns = useMemo(() => {
    return allcolumns.filter((column) => {
      // Add conditions to hide specific columns based on pipelineType
      if (pipelineType === "retail") {
        return !["companyName", "entityName"].includes(column?.id as string);
      }
      if (pipelineType === "corporate") {
        return !["name", "entityName"].includes(column?.id as string);
      }
      if (pipelineType === "partner") {
        return !["companyName", "name"].includes(column?.id as string);
      }
      return true;
    });
  }, [pipelineType]);

  const fetchopportunity = async () => {
    setLoading(true);
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<GetOpportunityReqType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      params: {
        pipelineType:
          pipelineType === "corporate" ? "coorporate" : pipelineType,
      },
    };
    try {
      const res = (await getOpportunities(payload)) as any;
      setOpportunities(() =>
        res?.data?.map((item: any) => {
          return {
            id: item?.id,
            uniqueId: item?.unique_id,
            companyName: item?._coorporate_company?.[0]?.company_name,
            product: item?._product?.name,
            assignedTo: item?._staff?.fname,
            createDate: new Date(item.created_at).toLocaleDateString(),
            opportunityStatus: statusValue(item.isOpen),
            companyId: item?._coorporate_company?.[0]?.id,
            entityName: item?._partner?.[0]?.entityName,
            name: item?._contacts?.[0]?.contactName,
            contactId: item?.contacts_id,
          };
        }),
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Failed to fetch Opportunities");
    }
  };
  useEffect(() => {
    fetchopportunity();
  }, [pipelineType]);
  return (
    <>
      {loading && <Spinner />}
      <div className="flex h-full gap-10 py-5 px-6 sm:px-0 w-full flex-col overflow-hidden">
        <div className=" flex w-full justify-between items-center">
          <div className=" flex flex-col gap-2">
            <h3 className=" font-semibold leading-4 text-lg text-black-label">
              <span className="capitalize">{pipelineType}</span> opportunities
            </h3>
            <p className="font-normal leading-[22.4px] text-sm text-[#7E7E7E]">
              List of all the recent {pipelineType} opportunities.
            </p>
          </div>
          <Link className="sm:hidden" to={"/opportunities/addopportunity"}>
            <button className="rounded-lg py-[11px] px-4 border bg-blue-700 text-white-A700 font-semibold text-sm">
              Add Opportunity
            </button>
          </Link>
        </div>
        <div className="flex-1 relative overflow-auto">
          {false && <Spinner />}
          <DataTable
            columns={columns}
            data={opportunities}
            isToolbarVisible={true}
            onRowClick={handleRowClick}
            isPaginationVisible={opportunities?.length > 10}
            searchData={searchOptions[pipelineType]}
            isPageCountVisible={false}
            defaultSorting={[{ id: "id", desc: true }]}
          />
        </div>
        <Link
          className="p-2.5 rounded-[20px] hidden sm:flex text-center border border-blue-700"
          to={"/opportunities/addopportunity"}
        >
          <button className="rounded-lg py-[11px] px-4 border w-full bg-blue-700 text-white-A700 font-semibold text-sm">
            Add Opportunity
          </button>
        </Link>
        <Toaster position="top-right" />
      </div>
    </>
  );
}
