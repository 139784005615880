import React from "react";

import { Img, Input, Text } from "../../components";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import {
  setPhone,
  setEmail,
  setPassportNumber,
} from "redux/features/CustomerRegistration/index";

import { CustomFromContext } from "./index";

const Step1: React.FC = () => {
  const { form } = React.useContext(CustomFromContext);
  const email = useAppSelector((state) => state.CustomerRegistration.email);
  const phone = useAppSelector((state) => state.CustomerRegistration.phone);
  const passport_number = useAppSelector(
    (state) => state.CustomerRegistration.passport_number,
  );

  const dispatch = useAppDispatch();

  const validInstructions = () => {
    if (!email || !phone || !passport_number) {
      return <Img src="/images/overview/exclamation.svg" />;
    }
    return <Img src="/images/login/green-tick.svg" />;
  };

  return (
    <div className=" flex ">
      <div className="flex md:flex-1 flex-col gap-5 md:h-auto items-start justify-start md:px-5 w-[53%] md:w-full">
        <div className="flex pl-10 flex-col gap-10 items-start justify-start w-[377px] sm:w-full">
          <div className="flex relative flex-col font-inter gap-2.5 items-start justify-start w-full">
            <div
              className={`flex items-center top-0 flex-col after:right-10  ${false ? "after:h-0 " : "after:h-32 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
            >
              <img
                className="relative -left-10 "
                src={`images/overview/ellipse.svg`}
                alt=""
              />
            </div>
            <Text className="text-sm font-medium leading-[17px]">
              What is the customer’s email ID ?
            </Text>
            <Input
              name="email"
              value={email}
              placeholder="Enter your email address"
              className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
              wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
              type="email"
              onChange={(e: string) => dispatch(setEmail(e))}
            ></Input>
          </div>
          {/* <Text
            className="text-[15px] text-black-900_bf tracking-[0.15px] w-full"
            size="txtRubikRomanBold15Black900bf"
          >
            OR
          </Text> */}
          <div className="flex flex-col relative gap-2.5 items-start justify-center w-full">
            <div
              className={`flex items-center top-0 flex-col after:right-10  ${!phone && !email ? "after:h-12" : !phone ? "after:h-32 after:border-diabled " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
            >
              <img
                className="relative -left-10 "
                src={`images/overview/ellipse.svg`}
                alt=""
              />
            </div>
            {email ? (
              <Text className="text-sm font-medium leading-[17px]">
                What is the customer’s phone number ?
              </Text>
            ) : (
              <Text className="text-sm text-diabled font-medium leading-[17px]">
                What is the customer’s phone number ?
              </Text>
            )}
            {email && (
              <Input
                name="phone_number"
                placeholder="Enter your phone number"
                type="number"
                className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                value={phone}
                onChange={(e: string) => {
                  dispatch(setPhone(e));
                }}
                errors={form.errors?.phone_number}
              ></Input>
            )}
          </div>
          <div className="flex relative flex-col gap-2.5 items-start justify-center w-full">
            <div
              className={`flex items-center top-0 flex-col after:right-10  ${!passport_number ? "after:h-0 " : "after:h-0"} absolute  after:relative  after:content-[''] `}
            >
              <img
                className={`relative -left-10 ${!phone ? "grayscale" : ""} `}
                src={`images/overview/ellipse.svg`}
                alt=""
              />
            </div>
            {phone ? (
              <Text className="text-sm font-medium leading-[17px]">
                What is the customer’s passport ?
              </Text>
            ) : (
              <Text className="text-sm text-diabled font-medium leading-[17px]">
                What is the customer’s passport ?
              </Text>
            )}
            {phone && (
              <Input
                name="passport_number"
                placeholder="Enter your Passport number"
                className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                value={passport_number}
                onChange={(e: string) => {
                  dispatch(setPassportNumber(e));
                }}
                errors={form.errors?.phone_number}
              ></Input>
            )}
          </div>
        </div>
      </div>
      <div className="border-solid flex sm:flex-1 flex-col md:h-auto md:hidden items-start justify-start md:px-5 sm:w-full">
        <div className="w-full flex flex-col gap-2">
          <h3 className="bg-[#F5F9FF] text-blue-700 py-3 px-4 rounded-md">
            Instruction
          </h3>
          <div className="flex py-2 px-4 items-center gap-5">
            {validInstructions()}
            <p className=" text-sm text-black-label">
              Please enter email or mobile number to continue
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step1;
