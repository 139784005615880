import { Option } from "pages/Opportunities/types";
import {
  entityTypes,
  FieldVisibility,
  pipelineFeatureRules,
} from "pages/Opportunities/utils/featureRule";
import { useSelectState } from "pages/Opportunities/utils/useSelect";
import { useState } from "react";
import ReactSelect, { SingleValue } from "react-select";
import {
  setCompanyEmail,
  setCompanyName,
  setCustomerContactAddress,
  setContactPersonName,
  setContactPersonPhone,
  setContactPersonType,
  setCustomerContactPhone,
  setCustomerContactName,
  setCustomerContactEmail,
  setEntityType,
  setEntityName,
  setEntityEmail,
  setEntityNumber,
} from "redux/features/CreateOpportunity";
import { useAppSelector, useAppDispatch } from "redux/hooks";

interface ContactDetailsProps {
  errors: Record<string, string>;
}

const typeOfContactPerson: Option[] = [
  { value: "employee", label: "Employee" },
  { value: "coordinator", label: "Coordinator" },
];

export default function ContactDetails({ errors }: ContactDetailsProps) {
  const {
    contact_person_type,
    company_email,
    entityName,
    entityEmail,
    entityType,
    entityNumber,
    customer_contact_address,
    customer_contact_email,
    customer_contact_name,
    customer_contact_phone,
    pipelineType,
    company_name,
    contact_person_name,
    contact_person_phone,
  } = useAppSelector((state) => state.CreateOpportunity);
  const dispatch = useAppDispatch();
  const [options, setOptions] = useState<Option[]>(typeOfContactPerson);
  const [inputValue, setInputValue] = useState<string>("");
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const dottedBorderWidth = "after:h-28 after:border-blue-700";

  const fieldVisibility: Partial<FieldVisibility> =
    pipelineFeatureRules[pipelineType] || {};

  const {
    options: entityOptions,
    selectedOption: selectedEntityType,
    handleInputChange: handleEntityInputChange,
    handleChange: handleEntityChange,
  } = useSelectState(
    entityTypes,
    null,
    (newValue) => {
      dispatch(setEntityType(newValue?.value as "firm" | "freelancer"));
    },
    (newValue) => {
      setInputValue(newValue);
    },
  );

  const handleChange = (newValue: SingleValue<Option>) => {
    // dispatch(setContactPersonType(newValue?.value??''));
    setSelectedOption(newValue);
    dispatch(setContactPersonType(newValue?.value ?? ""));
  };

  const handleInputChange = (newValue: string) => {
    setInputValue(newValue);
  };

  switch (pipelineType) {
    case "retail":
      return (
        <form className="max-w-md">
          <div className="flex flex-col sm:pl-0 pl-10 gap-8 w-full ">
            {fieldVisibility.customer_contact_phone && (
              <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                <div
                  className={`flex sm:hidden items-center top-0 flex-col after:right-10  ${fieldVisibility.customer_contact_phone ? (errors?.company_phone ? "after:h-36 after:border-blue-700" : dottedBorderWidth) : "after:h-0"}  absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                >
                  <img
                    className="relative -left-10 "
                    src="/images/overview/ellipse.svg"
                    alt=""
                  />
                </div>
                <label
                  htmlFor="customer_contact_phone"
                  className="text-sm text-[#6B7280]"
                >
                  What is the person's contact number?{" "}
                </label>
                <input
                  type="number"
                  disabled
                  onWheel={(e) => e.currentTarget.blur()}
                  placeholder="Enter the contact number"
                  id="customer_contact_phone"
                  onChange={(e) =>
                    dispatch(setCustomerContactPhone(e.target.value))
                  }
                  value={customer_contact_phone}
                  className="border border-black-label rounded-lg p-4 w-full"
                />
              </div>
            )}
            {fieldVisibility.customer_contact_name && (
              <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                <div
                  className={`flex sm:hidden  items-center top-0 flex-col after:right-10  ${fieldVisibility.customer_contact_name ? dottedBorderWidth : "after:h-0"}  absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                >
                  <img
                    className="relative -left-10 "
                    src="/images/overview/ellipse.svg"
                    alt=""
                  />
                </div>
                <label
                  htmlFor="customer_contact_name"
                  className="text-sm text-[#6B7280]"
                >
                  What is the name of the customer?
                </label>
                <input
                  type="text"
                  placeholder="Enter the customer name"
                  onChange={(e) =>
                    dispatch(setCustomerContactName(e.target.value))
                  }
                  id="customer_contact_name"
                  value={customer_contact_name}
                  className="border border-black-label rounded-lg p-4 w-full"
                />
              </div>
            )}
            {fieldVisibility.customer_contact_email && (
              <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                <div
                  className={`flex sm:hidden  items-center top-0 flex-col after:right-10  ${fieldVisibility.customer_contact_email ? dottedBorderWidth : "after:h-0"}  absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                >
                  <img
                    className="relative -left-10 "
                    src="/images/overview/ellipse.svg"
                    alt=""
                  />
                </div>
                <label
                  htmlFor="customer_contact_email"
                  className="text-sm text-[#6B7280]"
                >
                  What is the email ID of the customer? (optional)
                </label>
                <input
                  type="email"
                  placeholder="Enter the customer email ID"
                  onChange={(e) =>
                    dispatch(setCustomerContactEmail(e.target.value))
                  }
                  id="customer_contact_email"
                  value={customer_contact_email}
                  className="border border-black-label rounded-lg p-4 w-full"
                />
                {errors?.company_email && (
                  <p className="text-red-500 text-sm">
                    {errors?.company_email}
                  </p>
                )}
              </div>
            )}
            {fieldVisibility.customer_contact_address && (
              <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                <div
                  className={`flex sm:hidden  items-center top-0 flex-col after:right-10  ${fieldVisibility.customer_contact_email ? "after:h-0" : dottedBorderWidth}   absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                >
                  <img
                    className="relative -left-10 "
                    src="/images/overview/ellipse.svg"
                    alt=""
                  />
                </div>
                <label
                  htmlFor="customer_contact_address"
                  className="text-sm text-[#6B7280]"
                >
                  What is the person's contact address? (optional){" "}
                </label>
                <textarea
                  placeholder="Enter the customer address"
                  id="customer_contact_address"
                  onChange={(e) =>
                    dispatch(setCustomerContactAddress(e.target.value))
                  }
                  value={customer_contact_address}
                  className="border border-black-label rounded-lg p-4 w-full"
                />
              </div>
            )}
          </div>
        </form>
      );

    case "corporate":
      return (
        <form className="max-w-md">
          <div className="flex flex-col sm:pl-0 pl-10 gap-8 w-full ">
            {fieldVisibility.company_name && (
              <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                <div
                  className={`flex sm:hidden  items-center top-0 flex-col after:right-10  ${fieldVisibility.company_email ? dottedBorderWidth : "after:h-0"}  absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                >
                  <img
                    className="relative -left-10 "
                    src="/images/overview/ellipse.svg"
                    alt=""
                  />
                </div>
                <label
                  htmlFor="company_name"
                  className="text-sm text-[#6B7280]"
                >
                  What is the name of company?
                </label>
                <input
                  type="text"
                  disabled
                  onChange={(e) => dispatch(setCompanyName(e.target.value))}
                  id="company_name"
                  value={company_name}
                  className="border border-black-label rounded-lg p-4 w-full"
                />
                {errors?.company_name && (
                  <p className="text-red-500 text-sm">{errors.company_name}</p>
                )}
              </div>
            )}
            {fieldVisibility.company_email && (
              <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                <div
                  className={`flex sm:hidden  items-center top-0 flex-col after:right-10  ${fieldVisibility.contact_person_name ? (errors?.company_email ? "after:h-36 after:border-blue-700" : dottedBorderWidth) : "after:h-0"}  absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                >
                  <img
                    className="relative -left-10 "
                    src="/images/overview/ellipse.svg"
                    alt=""
                  />
                </div>
                <label
                  htmlFor="company_email"
                  className="text-sm text-[#6B7280]"
                >
                  What is the email ID of the company?{" "}
                </label>
                <input
                  type="email"
                  placeholder="Enter the company email ID"
                  onChange={(e) => dispatch(setCompanyEmail(e.target.value))}
                  id="company_email"
                  value={company_email}
                  className="border border-black-label rounded-lg p-4 w-full"
                />
                {errors?.company_email && (
                  <p className="text-red-500 text-sm">
                    {errors?.company_email}
                  </p>
                )}
              </div>
            )}
            {fieldVisibility.contact_person_name && (
              <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                <div
                  className={`flex sm:hidden  items-center top-0 flex-col after:right-10  ${fieldVisibility.contact_person_phone ? (errors?.contact_person ? "after:h-36 after:border-blue-700" : dottedBorderWidth) : "after:h-0"}  absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                >
                  <img
                    className="relative -left-10 "
                    src="/images/overview/ellipse.svg"
                    alt=""
                  />
                </div>
                <label
                  htmlFor="contact_person"
                  className="text-sm text-[#6B7280]"
                >
                  What is the name of the contact person (employee)?
                </label>
                <input
                  type="text"
                  placeholder="Enter name of the employee"
                  onChange={(e) =>
                    dispatch(setContactPersonName(e.target.value))
                  }
                  id="contact_person"
                  value={contact_person_name}
                  className="border border-black-label rounded-lg p-4 w-full"
                />
                {errors?.contact_person && (
                  <p className="text-red-500 text-sm">
                    {errors.contact_person}
                  </p>
                )}
              </div>
            )}

            {fieldVisibility.contact_person_phone && (
              <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                <div
                  className={`flex sm:hidden  items-center top-0 flex-col after:right-10  ${fieldVisibility.contact_person_type ? (errors?.company_phone ? "after:h-36 after:border-blue-700" : dottedBorderWidth) : "after:h-0"}  absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                >
                  <img
                    className="relative -left-10 "
                    src="/images/overview/ellipse.svg"
                    alt=""
                  />
                </div>
                <label
                  htmlFor="contact_person_phone"
                  className="text-sm text-[#6B7280]"
                >
                  What is the person's contact number?{" "}
                </label>
                <input
                  type="number"
                  onWheel={(e) => e.currentTarget.blur()}
                  placeholder="Enter the contact number"
                  id="contact_person_phone"
                  onChange={(e) =>
                    dispatch(setContactPersonPhone(e.target.value))
                  }
                  value={contact_person_phone}
                  className="border border-black-label rounded-lg p-4 w-full"
                />
                {errors?.contact_person_phone && (
                  <p className="text-red-500 text-sm">
                    {errors?.contact_person_phone}
                  </p>
                )}
              </div>
            )}
            {fieldVisibility.contact_person_type && (
              <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                <div className="flex sm:hidden  items-center top-0 flex-col after:right-10  after:h-0 absolute  after:relative  after:content-[''] after:border after:border-dashed">
                  <img
                    className="relative -left-10 "
                    src="/images/overview/ellipse.svg"
                    alt=""
                  />
                </div>
                <label
                  htmlFor="contact_person_type"
                  className="text-sm text-[#6B7280]"
                >
                  What is the type of the contact person?
                </label>
                <ReactSelect
                  className="w-full"
                  placeholder="Select the type of the contact person"
                  menuPlacement="top"
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      zIndex: 100,
                      borderRadius: ".5rem",
                      padding: ".6rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderColor: state.isFocused ? "#165FE3" : "#333333",
                      boxShadow: "none",
                    }),
                    menu: (provided, state) => ({
                      ...provided,
                      zIndex: 1000,
                    }),
                  }}
                  isClearable
                  isSearchable
                  options={options}
                  onInputChange={handleInputChange}
                  onChange={handleChange}
                  value={
                    contact_person_type
                      ? {
                          value: contact_person_type,
                          label: options.find(
                            (el) => el.value === contact_person_type,
                          )?.label as string,
                        }
                      : selectedOption
                  }
                  name="contact_person_type"
                  id="contact_person_type"
                />
                {errors?.contact_person_type && (
                  <p className="text-red-500 text-sm">
                    {errors.contact_person_type}
                  </p>
                )}
              </div>
            )}
          </div>
        </form>
      );
    case "partner":
      return (
        <form className="max-w-md">
          <div className="flex flex-col sm:pl-0 pl-10 gap-8 w-full ">
            {fieldVisibility.entityName && (
              <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                <div
                  className={`flex sm:hidden  items-center top-0 flex-col after:right-10  ${fieldVisibility.entityName ? (errors?.company_phone ? "after:h-36 after:border-blue-700" : dottedBorderWidth) : "after:h-0"}  absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                >
                  <img
                    className="relative -left-10 "
                    src="/images/overview/ellipse.svg"
                    alt=""
                  />
                </div>
                <label htmlFor="entity_name" className="text-sm text-[#6B7280]">
                  What is the name of entity?
                </label>
                <input
                  type="text"
                  disabled
                  onWheel={(e) => e.currentTarget.blur()}
                  placeholder="Enter the name"
                  id="entity_name"
                  onChange={(e) => dispatch(setEntityName(e.target.value))}
                  value={entityName}
                  className="border border-black-label rounded-lg p-4 w-full"
                />
              </div>
            )}
            {fieldVisibility.entityType && (
              <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                <div
                  className={`flex sm:hidden  items-center top-0 flex-col after:right-10  ${fieldVisibility.entityType ? dottedBorderWidth : "after:h-0"}  absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                >
                  <img
                    className="relative -left-10 "
                    src="/images/overview/ellipse.svg"
                    alt=""
                  />
                </div>
                <label htmlFor="entity_type" className="text-sm text-[#6B7280]">
                  What is the type of the entity?
                </label>
                <ReactSelect
                  className="w-full"
                  placeholder="Select the type of the entity"
                  menuPlacement="top"
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      zIndex: 100,
                      borderRadius: ".5rem",
                      padding: ".6rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderColor: state.isFocused ? "#165FE3" : "#333333",
                      boxShadow: "none",
                    }),
                    menu: (provided, state) => ({
                      ...provided,
                      zIndex: 1000,
                    }),
                  }}
                  isClearable
                  isSearchable
                  options={entityOptions}
                  onInputChange={handleEntityInputChange}
                  onChange={handleEntityChange}
                  value={selectedEntityType}
                  name="entity_type"
                  id="entity_type"
                />
              </div>
            )}
            {fieldVisibility.entityEmail && (
              <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                <div
                  className={`flex sm:hidden  items-center top-0 flex-col after:right-10  ${fieldVisibility.entityEmail ? dottedBorderWidth : "after:h-0"}  absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                >
                  <img
                    className="relative -left-10 "
                    src="/images/overview/ellipse.svg"
                    alt=""
                  />
                </div>
                <label
                  htmlFor="entity_email"
                  className="text-sm text-[#6B7280]"
                >
                  What is the email of the entity?
                </label>
                <input
                  type="email"
                  placeholder="Enter the email ID"
                  onChange={(e) => dispatch(setEntityEmail(e.target.value))}
                  id="entity_email"
                  value={entityEmail}
                  className="border border-black-label rounded-lg p-4 w-full"
                />
                {errors?.company_email && (
                  <p className="text-red-500 text-sm">
                    {errors?.company_email}
                  </p>
                )}
              </div>
            )}
            {fieldVisibility.entityNumber && (
              <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                <div
                  className={`flex sm:hidden  items-center top-0 flex-col after:right-10  ${true ? "after:h-0" : dottedBorderWidth}   absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                >
                  <img
                    className="relative -left-10 "
                    src="/images/overview/ellipse.svg"
                    alt=""
                  />
                </div>
                <label
                  htmlFor="entity_number"
                  className="text-sm text-[#6B7280]"
                >
                  What is the contact number of the entity?{" "}
                </label>
                <input
                  type="number"
                  onWheel={(e) => e.currentTarget.blur()}
                  placeholder="Enter the contact number"
                  id="entity_number"
                  onChange={(e) => dispatch(setEntityNumber(e.target.value))}
                  value={entityNumber}
                  className="border border-black-label rounded-lg p-4 w-full"
                />
                {errors?.contact_person_phone && (
                  <p className="text-red-500 text-sm">
                    {errors?.contact_person_phone}
                  </p>
                )}
              </div>
            )}
          </div>
        </form>
      );
    default:
      return null;
  }
}
